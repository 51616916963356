<template>
 <dash-page-new
     :title="$t('Schedule')"
     :subtitle="$t('Groups')"
     :root="$t('Dashboard')"
     :root-location="businessDashLink('')"

     :filters="filters"
     :filters-input="$store.state.settings.filtersData || {}"
     @filter="$store.state.settings.filtersData = $event"

 >

   <template #header_action>
     <ws-button @click="openNewSchedule" label="wsu.schedule.add" :disabled="groupsSelectFiltered.length === 0"  />
   </template>

   <template #default>

     <ws-data-table
         class="mt-5"
         :items="itemsFiltered"
         :headers="headers"
         :row-action="($event) => $router.push(businessDashLink('schedule/' + $event.uuid))"
     >
       <template #item.name="{item}">
         <h5 class="text-no-wrap" :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
       </template>
       <template #item.education_program_name="{item}">
         <h5>{{ item.education_program_name }}</h5>
       </template>
       <template #item.degree_name="{item}">
         <h5>{{ item.degree_name }}</h5>
       </template>
       <template #item.year="{item}">
         <h5>{{ item.year }}</h5>
       </template>
     </ws-data-table>
   </template>

   <template #dialog>

     <ws-dialog
         v-model="displayDialog"
         @save="addEntity"
         :title="$t('wsu.schedule.add')"
     >
       <ws-select
           v-model="entityData.group_id"
           :items="groupsSelectFiltered"
           autocomplet
           :label="$t('Group')"
           :placeholder="$t('wsu.structure.group.choose')"
       />

     </ws-dialog>
   </template>


 </dash-page-new>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "DashSchedule",
  data() {
    return {
      items : [],
      displayDialog : false,
      entityData : {},
      groupsSelect : [],
      editEntity : false,
      universitySpecialtiesSelect : [],
      degreesSelectArray : [],
      departmentsSelect : [],
      facultiesSelect : [],
      institutesSelect : [],
    }
  },
  computed : {
    ...mapState('settings' , ['filtersData']),

    itemsFiltered() {
      let items = this.COPY(this.items)

      // Specialty filter
      if ( this.filtersData.specialty && Array.isArray(this.filtersData.specialty) && this.filtersData.specialty.length > 0 ) {
        items = items.filter( el => this.filtersData.specialty.includes(el.university_specialty_id) )
      }
      // Year filter
      if ( this.filtersData.year ) {
        items = items.filter( el => el.year === this.filtersData.year  )
      }

      // Degree filter
      if ( this.filtersData.degree && Array.isArray(this.filtersData.degree) && this.filtersData.degree.length > 0 ) {
        items = items.filter( el => this.filtersData.degree.includes(el.degree_id) )
      }
      // Department filter
      if ( this.filtersData.department && Array.isArray(this.filtersData.department) && this.filtersData.department.length > 0 ) {
        items = items.filter( el => this.filtersData.department.includes(el.department_id) )
      }
      // Faculty filter
      if ( this.filtersData.faculty && Array.isArray(this.filtersData.faculty) && this.filtersData.faculty.length > 0 ) {
        items = items.filter( el => this.filtersData.faculty.includes(el.faculty_id) )
      }
      // Institute filter
      if ( this.filtersData.institute && Array.isArray(this.filtersData.institute) && this.filtersData.institute.length > 0 ) {
        items = items.filter( el => this.filtersData.institute.includes(el.institute_id) )
      }

      return items
    },

    filters() {
      return [
        { text : this.$t('Specialty') , value : 'specialty' , type : 'select_multiple' , items : this.universitySpecialtiesSelect },
        { text : this.$t('Degree') , value : 'degree' , type : 'select_multiple' , items : this.degreesSelectArray },
        { text : this.$t('Year') , value : 'year' , type : 'select' , items : this.yearSelect },
        { text : this.$t('Department') , value : 'department' , type : 'select_multiple' , items : this.departmentsSelect },
        { text : this.$t('Faculty') , value : 'faculty' , type : 'select_multiple' , items : this.facultiesSelect },
        { text : this.$t('Institutes') , value : 'institute' , type : 'select_multiple' , items : this.institutesSelect },
      ]
    },
    yearSelect() {
      return [
        { text : 1 + ' ' + this.$t('Year') , value : 1 },
        { text : 2 + ' ' + this.$t('Year') , value : 2 },
        { text : 3 + ' ' + this.$t('Year') , value : 3 },
        { text : 4 + ' ' + this.$t('Year') , value : 4 },
        { text : 5 + ' ' + this.$t('Year') , value : 5 },
        { text : 6 + ' ' + this.$t('Year') , value : 6 },
      ]
    },

    groupsSelectFiltered() {
      let items = this.COPY(this.groupsSelect)

      if ( this.items.length > 0 ) {
        items = items.filter(item => !this.items.map(el => el.group_id).includes(item.value))
      }

      return items
    },
    headers() {
      return [
        { text : this.$t('Group') , value : 'name'  , width: 15},
        { text : this.$t('EducationProgram') , value : 'education_program_name' },
        { text : this.$t('Year') , value : 'year' , width : 15 },
        { text : this.$t('Degree') , value : 'degree_name'  , width: 15},
      ]
    }
  },
  methods : {
    ...mapActions('wsu_schedule' , [
        'GET_SCHEDULES',
        'ADD_EDIT_SCHEDULE'
    ]),

    async addEntity() {

      if ( !this.entityData.group_id ) {
        this.nameError = true
        return this.notify(this.$t('wsu.structure.group.choose') , 'warning')
      }

      let result = await this.ADD_EDIT_SCHEDULE(this.entityData)
      if ( !result ) {
        this.notify(this.$t('NetworkError'))
        return
      }
      if ( !this.editEntity ) {
        this.items.push(result)
        this.notify(this.$t('wsu.schedule.created') , 'success')
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid )
        if (index === -1 ) {
          return
        }
        this.items[index] = result
        this.items = this.COPY(this.items)
        this.notify(this.$t('ChangesSaved'))
      }

      this.displayDialog = false
    },

    openNewSchedule() {
      this.editEntity = false
      this.entityData = {}
      this.displayDialog = true
    },
    async initData() {
      let result = await this.GET_SCHEDULES()
      if ( !result ) {
        return
      }
      this.items = result.schedules
      this.groupsSelect = result.groups_select

      this.universitySpecialtiesSelect = result.university_specialties_select
      this.degreesSelectArray = result.degrees_select
      this.departmentsSelect = result.departments_select
      this.facultiesSelect = result.faculties_select
      this.institutesSelect = result.institutes_select

    }
  },
  mounted() {
    this.initData()
  }
}
</script>

<style scoped>

</style>
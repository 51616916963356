var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dash-page-new',{attrs:{"title":_vm.$t('Schedule'),"subtitle":_vm.$t('Groups'),"root":_vm.$t('Dashboard'),"root-location":_vm.businessDashLink(''),"filters":_vm.filters,"filters-input":_vm.$store.state.settings.filtersData || {}},on:{"filter":function($event){_vm.$store.state.settings.filtersData = $event}},scopedSlots:_vm._u([{key:"header_action",fn:function(){return [_c('ws-button',{attrs:{"label":"wsu.schedule.add","disabled":_vm.groupsSelectFiltered.length === 0},on:{"click":_vm.openNewSchedule}})]},proxy:true},{key:"default",fn:function(){return [_c('ws-data-table',{staticClass:"mt-5",attrs:{"items":_vm.itemsFiltered,"headers":_vm.headers,"row-action":function ($event) { return _vm.$router.push(_vm.businessDashLink('schedule/' + $event.uuid)); }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-no-wrap",style:(("color : " + _vm.wsACCENT))},[_vm._v(_vm._s(item.name))])]}},{key:"item.education_program_name",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(_vm._s(item.education_program_name))])]}},{key:"item.degree_name",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(_vm._s(item.degree_name))])]}},{key:"item.year",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(_vm._s(item.year))])]}}])})]},proxy:true},{key:"dialog",fn:function(){return [_c('ws-dialog',{attrs:{"title":_vm.$t('wsu.schedule.add')},on:{"save":_vm.addEntity},model:{value:(_vm.displayDialog),callback:function ($$v) {_vm.displayDialog=$$v},expression:"displayDialog"}},[_c('ws-select',{attrs:{"items":_vm.groupsSelectFiltered,"autocomplet":"","label":_vm.$t('Group'),"placeholder":_vm.$t('wsu.structure.group.choose')},model:{value:(_vm.entityData.group_id),callback:function ($$v) {_vm.$set(_vm.entityData, "group_id", $$v)},expression:"entityData.group_id"}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }